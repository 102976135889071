import FormStep from "../FormStep";
import LegalConsent from "../LegalConsent";
import { NavigationButton } from "../NavigationButton";
import StepIndicator from "../StepIndicator";
import { useStoreActions, useStoreSelectors, useStoreState } from "../../store";
import { useEffect, useRef } from "react";
import { getThemeStyles, getThemeClassString } from '../../themes';
import OtherOptionsGroup from "../OtherOptionsGroup";

export default function StepperListForm() {
  const { settings } = useStoreState();
  const { selectCurrentStep, selectCanAdvance, selectStepNumber, selectHasPreviousStep, selectNextButtonText } = useStoreSelectors();
  const { navigateBack, navigateForward } = useStoreActions();
  const currentStep = selectCurrentStep();
  const canAdvance = selectCanAdvance();
  const stepNumber = selectStepNumber();
  const hasPreviousStep = selectHasPreviousStep();
  const nextButtonText = selectNextButtonText();

  const nextButtonBackgroundClass = settings.nextButtonBackgroundClass ?? "bg-pink-electric";
  const formLayoutClasses = settings.formLayoutClasses ?? "w-3/4 mx-auto";

  const showLegalConsent = currentStep.lastStep && settings.showLegalConsentText;
  const showOtherOptions = currentStep.showOtherOptions === true;
  const StepText = settings.stepText ?? "STEP";
  const stepOfText = settings.stepOfText ?? "OF";
  const LegalText = settings.stepLegalText ?? "SUBJECT TO";
  const legalLinkText = settings.stepLegalLinkText ?? "TERMS & CONDITIONS.";

  const showBackButton = settings.backButton && hasPreviousStep;
  const theme = settings.theme ?? {};
  const themeClasses = getThemeStyles(theme);

  const questionElementRef = useRef(null);
  useEffect(() => {
    const questionElement = questionElementRef.current;
    if (questionElement == null) {
      return;
    }
    if (currentStep.firstStep) {
      return;
    }
    if (currentStep.answers?.some((answer) => answer.selected)) {
      return;
    }
    questionElement.focus();
  }, [currentStep]);

  return (
    <div className={`${getThemeClassString(themeClasses, ['headerBackground', 'textColor', 'borderRadius'])}`} ref={questionElementRef}>
   {currentStep &&
    <div className={`font-bold text-center text-xl ${getThemeClassString(themeClasses, ['headerTextColor', 'paddingY'], 'mb-8 ')}`}>
      {currentStep.question}
    </div>}
      <div className="mx-auto">
        <StepIndicator
          stepText = {StepText}
          StepOfText = {stepOfText}
          total={settings.stepper}
          current={stepNumber}
          baseBgClass="bg-stepper-base"
          selectedBgClass= {getThemeClassString(themeClasses, ['indicatorBgColor'], 'bg-stepper-selected')}
          heigth={3}
          showStepText={true}
          theme={theme}
        />
        <div className={`w-auto ${getThemeClassString(themeClasses, ['backgroundColor', 'bottomBorderRadius', 'padding'])} ${formLayoutClasses}`}>
          <FormStep />
          {settings.stepLegalLink && (
          <div className={`${currentStep.lastStep ? "hidden" : theme ? "mt-6" : "mt-2 mb-8"}`}>
           <p className={`text-xs text-center uppercase ${getThemeClassString(themeClasses, ['headerTextColor', 'paddingY'])}`}>
           {LegalText}
           {" "}
                <a href={settings.stepLegalLink} target="_blank"  className="underline">
                  {legalLinkText}
                </a>
              </p>
            </div>
          )}
          {showLegalConsent && <LegalConsent />}

          {showOtherOptions && <OtherOptionsGroup title={currentStep.otherOptionsLabel} />}

          <div className={`mt-4 w-full flex ${theme ? 'justify-center' : 'justify-between'} gap-4`}>
            {showBackButton && <NavigationButton text="Previous" backgroundClasses={"bg-gray-400"} onNavButtonClicked={() => navigateBack()} />}
            <NavigationButton
              text={nextButtonText}
              backgroundClasses={nextButtonBackgroundClass}
              onNavButtonClicked={() => navigateForward()}
              disabled={!canAdvance}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
