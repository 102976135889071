import PropTypes from "prop-types";
import { AnswerButton } from "./SelectionButtons/AnswerButton";
import { TextInput } from "./TextInput";
import { SelectInput } from "./SelectInput";
import { CircularButton } from "./SelectionButtons/CircularButton";
import { ListItemButton } from "./SelectionButtons/ListItemButton";
import { useStoreActions, useStoreSelectors, useStoreState } from "../store";
import { PhoneInput } from "./PhoneInput";
import { ZipCodeInput } from "./ZipCodeInput";
import { NearestLocationsSelect } from "./NearestLocationsSelect";

export default function FormStep({showOtherOptions = false}) {
  const { settings } = useStoreState();
  const { selectCurrentStep, selectButtonSelectionType } = useStoreSelectors();
  const { changeAnswer } = useStoreActions();
  const currentStep = selectCurrentStep();
  const buttonSelectionType = selectButtonSelectionType();
  const { type, inputs } = currentStep;
  const answers = currentStep.answers?.filter((answer) => !!answer.isOtherOption === showOtherOptions);
  const isAnswerSelected = (answerValue) => answers.find((answer) => answer.value === answerValue)?.selected;

  const onButtonClicked = (answerValue) => changeAnswer(answerValue);
  const isDarkMode = settings.formType === "darkmode";

  const widthClass = type === "input" && !settings.fullWidthFormStep ? "lg:w-3/4" : "w-full";
  const justifyClass = isDarkMode ? "justify-center" : "";
  const gapClasses = currentStep.gapClasses ?? (isDarkMode ? "gap-2 sm:gap-4 mb-2" : "gap-2");
  const layoutClasses = currentStep.layoutClasses ?? "grid grid-cols-1 xxs:grid-cols-2 xs:flex xs:flex-wrap";

  return (
    <div className={`list-item-button-container max-w-2xl ${layoutClasses} ${justifyClass} ${gapClasses} ${widthClass}`}>
      {type === "button-selection" &&
        answers.map((answer) => {
          if (buttonSelectionType === "circular") {
            return <CircularButton answer={answer} key={answer.value} isAnswerSelected={isAnswerSelected} changeAnswer={onButtonClicked} />;
          }
          if (buttonSelectionType === "listitem") {
            return (
              <ListItemButton
                answer={answer}
                key={answer.value}
                isAnswerSelected={isAnswerSelected}
                changeAnswer={onButtonClicked}
                buttonWidthClass={currentStep.buttonWidthClass ?? "w-full"}
              />
            );
          }
          return <AnswerButton answer={answer} key={answer.value} isAnswerSelected={isAnswerSelected} changeAnswer={onButtonClicked} />;
        })}
      {type === "input" &&
        inputs.map((input) => {
          if (input.type === "zipcode") {
            return <ZipCodeInput inputData={input} key={input.name} />;
          }
          if (input.type == "tel") {
            return <PhoneInput inputData={input} key={input.name} />;
          }
          if (input.type == "select") {
            return <SelectInput inputData={input} key={input.name} />;
          }
          const textTypes = ["text", "number", "email"];
          if (textTypes.includes(input.type)) {
            return <TextInput inputData={input} key={input.name} />;
          }
        })}
      {type === "nearest-locations" && <NearestLocationsSelect />}
    </div>
  );
}

FormStep.propTypes = {
  showOtherOptions: PropTypes.bool,
};